<template>

    <svg class="checkbox" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" aria-hidden="true" focusable="false">
        <g transform="translate(-326 -873)">
            <g class="check-box"
               transform="translate(326 873)"
               fill="none"
               stroke="#900"
               stroke-linecap="round"
               stroke-linejoin="round"
               stroke-width="3">
                <rect width="30" height="30" stroke="none" />
                <rect x="1.5" y="1.5" width="27" height="27" fill="none" />
            </g>
            <path class="check-mark"
                  d="M4027.645,4556.323l4.481,4.847,10.035-12.544"
                  transform="translate(-3693.955 -3667.098)"
                  fill="none"
                  stroke="#900"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3" />
        </g>
    </svg>

</template>

<script>

    export default {
    
        name: 'Checkbox',

        props: {
            checked: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                componentName: this.$options.name + ' Component'
            };
        },
        
        created() {
            
        },
        
        mounted() {
            
        },
        
        methods: {
            
        }
        
    };

</script>

<style lang="scss" scoped>

    .checkbox {
    
    }

</style>
