/* DONATION */

import emailjs from 'emailjs-com';


import { RESET, UPDATE, SEND } from './actions';
import { RESET_DONATION, SET_DONATION } from './mutations';

const state = {
    donation: {}
};

const getters = {

    donation: ({ donation }) => donation

};

const mutations = {

    [SET_DONATION](state, obj) {
        //console.log('%cSET_DONATION', 'color:lime;', state.donation, obj);
        state.donation = {
            ...state.donation,
            ...obj
        };
        //console.log('state.donation', state.donation);
    },

    [RESET_DONATION](state) {
        //console.log('%cRESET_DONATION', 'color:lime;');
        state.donation = {};
        //console.log('state.donation', state.donation);
    }

};

const actions = {

    [UPDATE]({ commit }, obj) {
        //console.log('%cUPDATE', 'color:yellow;');
        commit('SET_DONATION', obj);
    },

    [RESET]({ commit }) {
        //console.log('%cRESET', 'color:yellow;');
        commit('RESET_DONATION');
    },

    [SEND]({ commit }, obj) {
        //console.log('%cSEND', 'color:yellow;');

        emailjs.send('service_hhk7phe', 'template_ia5lvxo', obj, 'user_jIBHL6jQbQFmbxGgHoUlM')
            .then((res) => {
                console.log('%cYay!', 'color:lime;', res.status, res.text);
            }, (err) => {
                console.log('%cBoo...', 'color:red;', err);
            });

        commit('RESET_DONATION');
    }

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
