<template>

    <div class="zoom" :class="{ connected: connected }">

        <zoom-call></zoom-call>

    </div>

</template>

<script>

import ZoomCall from '../components/ZoomCall';

let vm = {};

export default {

    name: 'Zoom',

    components: {
        ZoomCall
    },

    data() {
        return {
            connected: false
        };
    },

    created() {
        vm = this;

        vm.$eventBus.$on('zoom::connect', () => {
            console.log('Connecting to Zoom conference...');
            document.querySelector('body').classList.add('hide-overflow');
            vm.connected = true;
            this.$eventBus.$emit('zoom::connected');
        });
    }

}

</script>

<style lang="scss" scoped>

    .zoom {
        background-color: #101010;
        width: 0%;
        min-height: 100%;
        box-shadow: 0 0 32px $black-25;
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        transition: all 0.3s ease-out;

        &.connected {
            width: 100%;
            opacity: 1;
            transition: all 0.3s ease-out;
        }
    }

</style>
