<template>

    <div class="submission"
         data-aos="zoom-in">
    
        <div class="inner">

            <div v-if="debug" style="opacity:0;display: inline-flex; margin: 0 0 1rem 0; padding: 0.5rem 0.85rem; font-weight: 600;color: black;background-color: lime; border-radius: 1rem;">
                <label for="bypass" style="display: flex; flex-flow: row nowrap; align-items: center; gap: 0.5rem; line-height: 1;">
                    <input type="checkbox" name="bypass" id="bypass" :checked="debug" value="1" tabindex="-1" /> <span style="line-height: 1;">SKIP EMAIL!!! (For debug: i.e. just check the video transition)</span>
                </label>
            </div>

            <button type="button" @click="validateSubmission" tabindex="0" :disabled="(!donation.selections || !donation.selections.length)">Submit</button>

        </div>
        
    </div>

</template>

<script>

    import { mapActions, mapGetters } from 'vuex';
    import { SEND } from '../store/donation/actions';

    export default {
    
        name: 'Submission',
        
        data() {
            return {
                componentName: this.$options.name + ' Component',
                errors: [],
                debug: false
            };
        },

        computed: {

            ...mapGetters('donation', {
                donation: 'donation'
            })

        },
        
        created() {
            
        },
        
        mounted() {
            
        },
        
        methods: {

            ...mapActions('donation', {
                sendDonation: SEND
            }),

            validateSubmission() {
                //console.log('validateSubmission->');
                this.errors = [];

                if (!Object.keys(this.donation).length) {
                    //console.log('%cError: Invalid donation.', 'color:red;');
                    this.errors.push('Invalid donation.');
                    return false;
                }

                if (!this.donation.selections || this.donation.selections.length <= 0) {
                    //console.log('%cError: No charities selected.', 'color:red;');
                    this.errors.push('No charities selected.');

                    document.querySelector('.section-header[data-index="01"]').scrollIntoView({
                        behavior: 'smooth'
                    });

                    return false;

                } else {
                    //console.log('%ccharity options selected.', 'color:dodgerblue;');
                    //console.log(this.donation.selections);

                    if (this.donation.selections.includes('Custom')) {
                        //console.log('%ccustom charity option found.', 'color:teal;');
                        // check for name & url
                        if (!this.donation.selectionCustomUrl) {
                            //console.log('%cError: Charity url not supplied.', 'color:red;');
                            this.errors.push('Charity Website URL is blank.');

                            document.querySelector('input#option_custom').scrollIntoView({
                                behavior: 'smooth'
                            });

                            document.querySelector('input.charity-url').focus();

                        } else {
                            //console.log(this.donation.selectionCustomUrl);
                        }
                        if (!this.donation.selectionCustomName) {
                            //console.log('%cError: Charity name not supplied.', 'color:red;');
                            this.errors.push('Charity Name is blank.');

                            document.querySelector('input#option_custom').scrollIntoView({
                                behavior: 'smooth'
                            });

                            document.querySelector('input.charity-name').focus();

                        } else {
                            //console.log(this.donation.selectionCustomName);
                        }
                    }
                }

                if (this.donation.fullName) {
                    //console.log('full name supplied.');
                    //console.log(this.donation.fullName);
                }

                if (this.donation.emailAddress) {
                    //console.log('email address supplied.');
                    //console.log(this.donation.emailAddress);
                }

                if (this.donation.zipCode) {
                    //console.log('zip code supplied.');
                    //console.log(this.donation.zipCode);
                }

                if (!this.errors.length) {
                    //console.log('No errors found in donation submission.');

                    if (!this.debug) {
                        // send processed donation submission
                        this.sendDonation({
                            name: this.donation.fullName || 'N/A',
                            email: this.donation.emailAddress || 'N/A',
                            zip: this.donation.zipCode || 'N/A',
                            charities: this.donation.selections || 'N/A',
                            charity_name: this.donation.selectionCustomName || 'N/A',
                            charity_url: this.donation.selectionCustomUrl || 'N/A'
                        });
                    }

                    // forward onto zoom call
                    //this.$router.push({ name: 'Zoom' });
                    this.$eventBus.$emit('zoom::connect');
                } else {
                    //console.log('Errors found in donation submission.');
                    // fix issues & re-submit
                }

            }

        }
        
    };

</script>

<style lang="scss" scoped>

    .submission {
        width: 100%;
        max-width: 815px;

        & > .inner {

        }

        button {
            background-color: $neo-yellow;
            font-family: $font-rubik;
            font-size: 1.75rem;
            font-weight: 700;
            color: $white-100;
            line-height: 1;
            text-transform: uppercase;
            padding: 1.25rem 3.75rem 1.125rem;
            border: 0 none;
            transform-origin: 50% 50%;
            transition: all 0.3s ease-out;
            will-change: transform;

            @media (min-width: 576px) { // RED
                font-size: 1.875rem;
                padding: 1.375rem 4.20rem 1.25rem;
            }

            @media (min-width: 768px) { // GREEN
                font-size: 2.188rem;
                padding: 1.500rem 6.750rem 1.375rem;
            }

            @media (min-width: 992px) { // BLUE

            }

            @media (min-width: 1200px) { // YELLOW

            }

            &:focus {
                background-color: $neo-red;
                outline: none;
                box-shadow: none;
                transform: scale(1.08);
                transition: all 0.3s ease-out;
            }

            &:disabled {
                background-color: $neo-grey-dark;
                color: $neo-off-white;
                opacity: 0.76;
                transition: all 0.3s ease-out;

                @media(hover: hover) {
                    &:hover {
                        background-color: $neo-grey-dark;
                        transform: scale(1);
                        transition: all 0.3s ease-out;
                        cursor: not-allowed;
                    }
                }
            }

            @media(hover: hover) {
                &:hover {
                    background-color: $neo-red;
                    transform: scale(1.08);
                    transition: all 0.3s ease-out;
                    cursor: pointer;
                }
            }
        }
    }

</style>
