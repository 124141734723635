import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import EventBusPlugin from './plugins/EventBusPlugin';

import AOS from 'aos';
import 'aos/dist/aos.css';

// global app styles
require('./style/index.scss');

Vue.config.productionTip = false;

Vue.use(EventBusPlugin);

new Vue({
    router,
    store,
    created() {
        AOS.init({
            disable: window.matchMedia('(prefers-reduced-motion: reduce)').matches,
            once: true,
            mirror: false,
            ease: 'ease-out'
        });
    },
    render: h => h(App)
}).$mount('#app');
