<template>

    <div id="charity-options"
         class="charity-options"
         data-aos="fade-up">
    
        <transition-group name="staggered-list"
                          tag="ul"
                          class="options-list"
                          :css="false"
                          @before-enter="beforeEnter"
                          @enter="enter"
                          @leave="leave">
            <li v-for="(option, idx) in filteredOptions" :key="option.id" :data-idx="idx">
                <div class="normal" :class="{ 'selected': selections.includes(option.name), 'disabled': !selections.includes(option.name) && selectionLimit }">
                    <input type="checkbox"
                           :id="'option_' + option.id"
                           v-model="selections"
                           :value="option.name"
                           :tabindex="idx + 1"
                           :disabled="selectionLimit && !selections.includes(option.name)"/>
                    <label :for="'option_' + option.id">
                        <span class="checkbox-wrapper">
                            <checkbox :checked="selections.includes(option.name)"></checkbox>
                        </span>
                        <span class="option-meta">
                            <span class="option-name" v-html="option.name"></span>
                            <span class="option-description" v-html="option.description"></span>
                        </span>
                    </label>
                </div>
                <div>
                    <a :href="option.url" target="_blank" :title="'Visit “' + option.name + '” Charity Website'" class="view-link" :tabindex="idx + 2">
                        <span v-html="labels.view"></span>
                        <span class="icon">
                            <icon-arrow-right></icon-arrow-right>
                        </span>
                    </a>
                </div>
            </li>
            <li v-if="(selectionLimit && selections.includes('Custom')) || (!selectionLimit)" :key="'custom'">
                <div class="custom-d1" :class="{ 'selected': selections.includes('Custom'), 'disabled': !selections.includes('Custom') && selectionLimit }">
                    <input type="checkbox"
                           :id="'option_custom'"
                           v-model="selections"
                           value="Custom"
                           @change="selectCustomChanged"
                           :tabindex="filteredOptions.length + 20"
                           :disabled="selectionLimit && !selections.includes('Custom')" />
                    <label :for="'option_custom'">
                        <span class="checkbox-wrapper">
                            <checkbox :checked="selections.includes('Custom')"></checkbox>
                        </span>
                        <span class="option-meta">
                            <span class="option-name custom" v-html="labels.add.name"></span>
                            <span class="option-charity-name">
                                <input type="text"
                                       v-model="selectionCustomName"
                                       :disabled="(selectionLimit && !selections.includes('Custom'))"
                                       @focus="selectCustomCheck"
                                       placeholder="Charity Name"
                                       ref="charityName"
                                       class="charity-name"
                                       :tabindex="filteredOptions.length + 21"/>
                            </span>
                            <span class="option-charity-url">
                                <input type="text"
                                       v-model="selectionCustomUrl"
                                       :disabled="(selectionLimit && !selections.includes('Custom'))"
                                       @focus="selectCustomCheck"
                                       placeholder="Charity Website URL"
                                       ref="charityUrl"
                                       class="charity-url"
                                       :tabindex="filteredOptions.length + 22"/>
                            </span>
                        </span>
                    </label>
                </div>
            </li>
        </transition-group>
        
    </div>

</template>

<script>

    import AOS from 'aos';
    import { gsap } from "gsap";
    import { mapActions, mapGetters } from 'vuex';
    import { UPDATE } from '../store/donation/actions';

    import Checkbox from '@/components/Checkbox';
    import IconArrowRight from '@/components/IconArrowRight';

    export default {
    
        name: 'CharityOptions',

        components: {
            Checkbox,
            IconArrowRight
        },

        props: {
            options: {
                type: Array,
                default: () => { return []; }
            }
        },
        
        data() {
            return {
                componentName: this.$options.name + ' Component',
                selections: [],
                maxSelections: 3,
                labels: {
                    view: 'View <br />Charity',
                    add: {
                        name: 'Add Your Own Charity'
                    }
                }
            };
        },

        watch: {
            selections(nVal) {
                this.setDonation({ selections: nVal });
            },
            selectionLimit(nVal) {
                if (nVal) {
                    document.querySelector('.section-header[data-index="01"]').scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            }
        },

        computed: {

            selectionCustomName: {
                get() {
                    return this.donation.selectionCustomName;
                },
                set(nVal) {
                    this.setDonation({ selectionCustomName: nVal });
                }
            },

            selectionCustomUrl: {
                get() {
                    return this.donation.selectionCustomUrl;
                },
                set(nVal) {
                    this.setDonation({ selectionCustomUrl: nVal });
                }
            },

            selectionLimit() {
                return this.selections.length >= this.maxSelections;
            },

            filteredOptions() {
                if (this.selectionLimit) {
                    return this.options.filter(opt => this.selections.includes(opt.name));
                } else {
                    return this.options;
                }
            }

        },
        
        created() {

        },
        
        mounted() {

        },
        
        methods: {

            ...mapActions('donation', {
                setDonation: UPDATE
            }),

            ...mapGetters('donation', {
                donation: 'donation'
            }),

            /**
             * @method selectCustomCheck
             * @description
             */
            selectCustomCheck() {
                if (!this.selections.includes('Custom')) {
                    this.selections.push('Custom');
                }
                this.$nextTick();
            },

            /**
             * @method selectCustomChanged
             * @description
             */
            selectCustomChanged() {
                if (!this.selections.includes('Custom')) {
                    this.selectionCustomName = '';
                    this.selectionCustomUrl = '';
                } else {
                    this.$refs.charityName.focus();
                }
            },

            beforeEnter: function(el) {
                gsap.set(el, {
                    opacity: 0,
                    //x: '+=20'
                });
            },

            enter: function(el, done) {
                let _delay = window.matchMedia('(min-width: 768px)') ? el.dataset.idx * 0.06 : 0;
                gsap.to(el, {
                    duration: window.matchMedia('(min-width: 768px)') ? 0.3 : 0,
                    opacity: 1,
                    //x: 0,
                    delay: _delay,
                    onComplete: function() {
                        done();
                    }
                });
            },

            leave: function(el, done) {
                let _delay = window.matchMedia('(min-width: 768px)') ? el.dataset.idx * 0.06 : 0;
                gsap.to(el, {
                    duration: window.matchMedia('(min-width: 768px)') ? 0.3 : 0,
                    opacity: 0,
                    //x: '+=20',
                    delay: _delay,
                    onComplete: function() {
                        done();
                        AOS.refresh();
                    }
                });
            }

        }
        
    };

</script>

<style lang="scss" scoped>

    .charity-options {
        width: 100%;
        max-width: 940px;
    }

    .options-list {
        width: 100%;
        max-width: 815px;

        & > li {
            margin: 0 0 1.5rem 0;
            display: flex;
            flex-flow: column nowrap;
            //gap: 0.5rem;
            position: relative;
            transition: all 0.3s ease-out;

            @media (min-width: 576px) { // RED
                flex-flow: row nowrap;
            }

            @media (min-width: 768px) { // GREEN
                margin: 0 0 1.125rem 0;
                flex-flow: row nowrap;
                //gap: 1rem;
            }

            @media (min-width: 992px) { // BLUE

            }

            @media (min-width: 1200px) { // YELLOW

            }

            &:last-of-type {
                margin: 0 0 0 0;

                & > div:nth-of-type(1):not(.normal) {
                    margin: 0 0 0 0;
                }
            }

            & > div:nth-of-type(1) { // control-label
                background-color: $white-100;
                width: 100%;
                margin: 0 0 0.5rem 0;
                padding: 20px 20px 20px 60px;
                border-radius: 0.688rem;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                //gap: 1.125rem;
                transition: all 0.3s ease-out;

                @media (min-width: 576px) { // RED
                    margin: 0 1.125rem 0 0;
                }

                @media (min-width: 768px) { // GREEN
                    padding: 22px 20px 22px 124px;
                }

                @media (min-width: 992px) { // BLUE
                    padding: 22px 50px 22px 124px;
                }

                @media (min-width: 1200px) { // YELLOW

                }

                &.selected {
                    background-color: $neo-yellow;
                    transition: all 0.3s ease-out;

                    & input[type="checkbox"] {

                        & + label {

                            & ::v-deep svg {

                                & g {

                                    & g {
                                        stroke: $white-100;
                                        transition: all 0.3s ease-out;
                                    }

                                    & path {
                                        stroke: $white-100;
                                        transition: all 0.3s ease-out;
                                    }
                                }
                            }
                        }
                    }

                    & .option-meta {

                        & .option-name {
                            //color: darken($neo-yellow, 45%);
                            color: $white-100;
                            transition: all 0.3s ease-out;

                            &:not(.custom) {
                                margin: 0 0 0 0;
                            }
                        }

                        & .option-description {
                            //color: darken($neo-yellow, 45%);
                            color: $white-100;
                            max-height: 0;
                            transition: all 0.3s ease-out;
                        }
                    }
                }

                &.disabled {

                    & .option-meta {

                        & .option-name {
                            color: lighten($neo-grey-dark, 30%);
                            transition: all 0.3s ease-out;
                        }

                        & .option-description {
                            color: lighten($neo-grey-dark, 30%);
                            transition: all 0.3s ease-out;
                        }
                    }

                    & input[type="checkbox"] {

                        &:not(:checked) {

                            & + label {
                                cursor: default;
                            }
                        }
                    }

                    & input[type="text"] {
                        color: lighten($neo-grey-dark, 30%);
                        transition: all 0.3s ease-out;

                        &::placeholder {
                            color: lighten($neo-grey-dark, 30%);
                            transition: all 0.3s ease-out;
                        }
                    }
                }

                & input[type="checkbox"] {
                    //display: none;
                    //visibility: hidden;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;

                    @media(hover: hover) {
                        &:not(:disabled):hover {
                            cursor: pointer;
                        }
                    }

                    &:focus {
                        outline: none;
                        box-shadow: none;

                        & + label {

                            & ::v-deep svg {
                                //filter: drop-shadow(1px 1px 0 $neo-yellow) drop-shadow(-1px -1px 0 $neo-yellow) drop-shadow(1px -1px 0 $neo-yellow) drop-shadow(-1px 1px 0 $neo-yellow);
                                //filter: drop-shadow(3px 3px 0 lighten($neo-red, 30%));
                                transition: all 0.3s ease-out;

                                & g {

                                    & g {
                                        stroke: $neo-yellow;
                                        transition: all 0.3s ease-out;
                                    }

                                    & path {
                                        stroke: $neo-yellow;
                                        opacity: 1;
                                        transition: all 0.3s ease-out;
                                    }
                                }
                            }

                        }
                    }

                    &:checked {

                        & + label {

                            & ::v-deep svg {
                                //filter: drop-shadow(1px 1px 0 darken($neo-yellow, 45%)) drop-shadow(-1px -1px 0 darken($neo-yellow, 45%)) drop-shadow(1px -1px 0 darken($neo-yellow, 45%)) drop-shadow(-1px 1px 0 darken($neo-yellow, 45%));
                                //filter: drop-shadow(3px 3px 0 $neo-red);
                                transition: all 0.3s ease-out;

                                & g {

                                    & g {
                                        //stroke: darken($neo-yellow, 45%);
                                        stroke: $white-100;
                                        transition: all 0.3s ease-out;
                                    }

                                    & path {
                                        //stroke: darken($neo-yellow, 45%);
                                        stroke: $white-100;
                                        opacity: 1;
                                        transition: all 0.3s ease-out;
                                    }
                                }
                            }
                        }

                        &:focus {
                            outline: none;
                            box-shadow: none;

                            & + label {

                                & ::v-deep svg {
                                    //filter: drop-shadow(1px 1px 0 darken($neo-yellow, 45%)) drop-shadow(-1px -1px 0 darken($neo-yellow, 45%)) drop-shadow(1px -1px 0 darken($neo-yellow, 45%)) drop-shadow(-1px 1px 0 darken($neo-yellow, 45%));
                                    //filter: drop-shadow(3px 3px 0 $neo-red);
                                    transition: all 0.3s ease-out;

                                    & g {

                                        & g {
                                            stroke: $neo-red;
                                            transition: all 0.3s ease-out;
                                        }

                                        & path {
                                            stroke: $neo-red;
                                            opacity: 1;
                                            transition: all 0.3s ease-out;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &:disabled {

                        & + label {

                            & ::v-deep svg {

                                & g {

                                    & g {
                                        stroke: lighten($neo-grey-dark, 30%);
                                        transition: all 0.3s ease-out;
                                    }

                                    & path {
                                        stroke: lighten($neo-grey-dark, 30%);
                                        opacity: 1;
                                        transition: all 0.3s ease-out;
                                    }
                                }
                            }
                        }
                    }

                    & + label {
                        display: block;
                        width: 100%;
                        position: relative;

                        @media(hover: hover) {
                            &:hover {
                                cursor: pointer;
                            }
                        }

                        & .checkbox-wrapper {
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: -44px;
                            transform: translateY(-50%);
                            transition: all 0.3s ease-out;

                            @media (min-width: 576px) { // RED

                            }

                            @media (min-width: 768px) { // GREEN
                                left: -74px;
                            }

                            @media (min-width: 992px) { // BLUE

                            }

                            @media (min-width: 1200px) { // YELLOW

                            }
                        }

                        & ::v-deep svg {
                            filter: drop-shadow(0 0 0 $transparent);
                            transition: all 0.3s ease-out;

                            & g {

                                & g {
                                    stroke: $neo-red;
                                    transition: all 0.3s ease-out;
                                }

                                & path {
                                    stroke: $neo-red;
                                    opacity: 0;
                                    transition: all 0.3s ease-out;
                                }
                            }
                        }
                    }
                }

                & input[type="text"] {
                    background-color: $neo-light-red;
                    font-family: $font-rubik;
                    font-size: 0.875rem;
                    font-weight: 500;
                    color: $neo-grey-dark;
                    line-height: 1;
                    text-transform: uppercase;
                    width: 100%;
                    height: 2.250rem;
                    padding: 0 1rem;
                    border: 3px solid $transparent;
                    border-radius: 18px;
                    transition: all 0.3s ease-out;

                    @media (min-width: 576px) { // RED

                    }

                    @media (min-width: 768px) { // GREEN
                        font-size: 1.125rem;
                        padding: 0 1.500rem;
                    }

                    @media (min-width: 992px) { // BLUE

                    }

                    @media (min-width: 1200px) { // YELLOW

                    }

                    &:focus {
                        outline: none;
                        border: 3px solid $neo-red;
                        box-shadow: none;
                        border-radius: 18px;
                    }

                    &::placeholder {
                        color: $neo-grey-dark;
                        transition: all 0.3s ease-out;
                    }
                }
            }

            & > div:nth-of-type(2) { // control-link
                width: 100%;
                max-width: none;
                transition: all 0.3s ease-out;

                @media (min-width: 576px) { // RED
                    max-width: 150px;
                }

                @media (min-width: 768px) { // GREEN
                    max-width: 150px;
                }

                @media (min-width: 992px) { // BLUE

                }

                @media (min-width: 1200px) { // YELLOW

                }
            }
        }
    }

    .option-meta {
        text-align: left;

        & .option-name {
            font-family: $font-rubik;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1;
            text-transform: uppercase;
            color: $neo-red;
            margin: 0 0 0.5rem 0;
            display: block;
            transition: all 0.3s ease-out;

            @media (min-width: 576px) { // RED

            }

            @media (min-width: 768px) { // GREEN
                font-size: 1.313rem;
            }

            @media (min-width: 992px) { // BLUE

            }

            @media (min-width: 1200px) { // YELLOW

            }
        }

        & .option-description {
            font-family: $font-work-sans;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.25;
            color: $neo-red;
            max-height: 640px;
            overflow: hidden;
            display: block;
            transition: all 0.3s ease-out;

            @media (min-width: 576px) { // RED

            }

            @media (min-width: 768px) { // GREEN
                font-size: 1rem;
            }

            @media (min-width: 992px) { // BLUE

            }

            @media (min-width: 1200px) { // YELLOW

            }
        }

        & .option-charity-name {
            width: 100%;
            margin: 0 0 0.5rem 0;
            display: block;
        }

        & .option-charity-url {
            width: 100%;
            display: block;
        }
    }

    .view-link {
        background-color: $white-100;
        font-family: $font-rubik;
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1.250;
        width: 100%;
        height: 100%;
        padding: 1rem;
        border-radius: 0.688rem;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        gap: 0.750rem;
        position: relative;
        transition: all 0.3s ease-out;

        @media (min-width: 576px) { // RED

        }

        @media (min-width: 768px) { // GREEN
            padding: 1.375rem;
            justify-content: flex-start;
        }

        @media (min-width: 992px) { // BLUE

        }

        @media (min-width: 1200px) { // YELLOW

        }

        &:focus {
            background-color: $neo-red;
            outline: none;
            box-shadow: none;
            transition: all 0.3s ease-out;

            & > span {
                color: $white-100;
                transition: all 0.3s ease-out;
            }

            & .icon {
                transform: translateX(4px);
                transition: all 0.3s ease-out;

                & ::v-deep svg {

                    & path {
                        //stroke: $neo-red;
                        stroke: $white-100;
                        transition: all 0.3s ease-out;
                    }
                }
            }
        }

        @media(hover: hover) {
            &:hover {
                background-color: $neo-red;
                transition: all 0.3s ease-out;

                & > span {
                    color: $white-100;
                    transition: all 0.3s ease-out;
                }

                & .icon {
                    transform: translateX(4px);
                    transition: all 0.3s ease-out;

                    & ::v-deep svg {

                        & path {
                            //stroke: $neo-red;
                            stroke: $white-100;
                            transition: all 0.3s ease-out;
                        }
                    }
                }
            }
        }


        & > span {
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.125;
            color: $neo-red;
            text-align: left;
            text-transform: uppercase;
            display: block;
            position: relative;
            filter: drop-shadow(0 0 0 $transparent);
            transition: all 0.3s ease-out;

            @media (min-width: 576px) { // RED

            }

            @media (min-width: 768px) { // GREEN

            }

            @media (min-width: 992px) { // BLUE

            }

            @media (min-width: 1200px) { // YELLOW

            }

            & ::v-deep br {
                display: none;
                transition: all 0.3s ease-out;

                @media (min-width: 576px) { // RED

                }

                @media (min-width: 768px) { // GREEN
                    display: initial;
                }

                @media (min-width: 992px) { // BLUE

                }

                @media (min-width: 1200px) { // YELLOW

                }
            }
        }

        & .icon {
            transition: all 0.3s ease-out;

            & ::v-deep svg {

                & path {
                    stroke: $neo-red;
                    transition: all 0.3s ease-out;
                }
            }
        }
    }

</style>
