<template>

    <svg class="icon-gift" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="53.918" height="67.441" viewBox="0 0 53.918 67.441">
        <defs>
            <clipPath id="icon-gift-clip-path">
                <rect id="Rectangle_298" data-name="Rectangle 298" width="53.918" height="67.441" transform="translate(0 0)" fill="none" stroke="#f0efeb" stroke-width="1"/>
            </clipPath>
        </defs>
        <g id="Group_97" data-name="Group 97" transform="translate(0 0)">
            <g id="Group_96" data-name="Group 96" clip-path="url(#icon-gift-clip-path)">
                <path id="Path_246" data-name="Path 246" d="M13.352,65.691H48.741a3.427,3.427,0,0,0,3.426-3.428V36.219H9.924V62.263A3.428,3.428,0,0,0,13.352,65.691Z" fill="none" stroke="#f0efeb" stroke-miterlimit="10" stroke-width="3.5"/>
                <path id="Path_247" data-name="Path 247" d="M8.01,35.847l41.243-21.49a3.277,3.277,0,0,0,1.392-4.42L49.176,7.118a3.277,3.277,0,0,0-4.42-1.392L3.513,27.217a3.275,3.275,0,0,0-1.391,4.42L3.59,34.455A3.275,3.275,0,0,0,8.01,35.847Z" fill="none" stroke="#f0efeb" stroke-miterlimit="10" stroke-width="3.5"/>
                <rect id="Rectangle_296" data-name="Rectangle 296" width="7.302" height="29.472" transform="translate(27.395 36.219)" fill="none" stroke="#f0efeb" stroke-miterlimit="10" stroke-width="3.5"/>
                <rect id="Rectangle_297" data-name="Rectangle 297" width="7.302" height="9.732" transform="translate(22.424 17.363) rotate(-27.527)" fill="none" stroke="#f0efeb" stroke-miterlimit="10" stroke-width="3.5"/>
                <path id="Path_248" data-name="Path 248" d="M25.635,15.559S13.7,4.921,11.614,11.912C9.22,19.942,25.635,15.559,25.635,15.559Z" fill="none" stroke="#f0efeb" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.5"/>
                <path id="Path_249" data-name="Path 249" d="M25.635,15.559S23.756-.32,30.679,1.977C38.632,4.616,25.635,15.559,25.635,15.559Z" fill="none" stroke="#f0efeb" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.5"/>
            </g>
        </g>
    </svg>

</template>

<script>

    export default {
    
        name: 'IconGift',
        
        data() {
            return {
                componentName: this.$options.name + ' Component'
            };
        },
        
        created() {
            
        },
        
        mounted() {
            
        },
        
        methods: {
            
        }
        
    };

</script>

<style lang="scss" scoped>

    .icon-gift {
    
    }

</style>
