import Vue from 'vue';
import Vuex from 'vuex';

// import modules
import charities from '@/store/charities';
import donation from '@/store/donation';

Vue.use(Vuex);

const Store =  new Vuex.Store({
    state: {
        awesome: true
    },
    modules: {
        charities,
        donation
    }
});

export default Store;
