<template>

    <div class="donate">

        <Donations msg="{ DONATIONS COMPONENT }" />

    </div>

</template>

<script>

import Donations from '@/components/Donations.vue'

export default {

    name: 'Home',

    components: {
        Donations
    }

}

</script>
