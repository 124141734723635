<template>

    <div class="personalization"
         data-aos="zoom-in">
    
        <div class="inner" id="personalization">

            <div class="form-row">
                <input type="text"
                       v-model="fullName"
                       placeholder="Full Name"
                       tabindex="0">
            </div>
            <div class="form-row">
                <input type="text"
                       v-model="emailAddress"
                       placeholder="Email Address"
                       tabindex="0"/>
                <input type="text"
                       v-model="zipCode"
                       placeholder="Zip Code"
                       class="zip-code"
                       tabindex="0"/>
            </div>
            <div class="form-row">
                <span class="note">* If you enter your zip code we’ll try to make sure your donation impacts your local area</span>
            </div>

        </div>
        
    </div>

</template>

<script>

    import { mapActions, mapGetters } from 'vuex';
    import { UPDATE } from '../store/donation/actions';

    export default {
    
        name: 'Personalization',
        
        data() {
            return {
                componentName: this.$options.name + ' Component'
            };
        },

        computed: {

            fullName: {
                get() {
                    return this.donation.fullName;
                },
                set(nVal) {
                    this.setDonation({ fullName: nVal });
                }
            },

            emailAddress: {
                get() {
                    return this.donation.emailAddress;
                },
                set(nVal) {
                    this.setDonation({ emailAddress: nVal });
                }
            },

            zipCode: {
                get() {
                    return this.donation.zipCode;
                },
                set(nVal) {
                    this.setDonation({ zipCode: nVal });
                }
            }

        },
        
        created() {
            
        },
        
        mounted() {
            
        },
        
        methods: {

            ...mapActions('donation', {
                setDonation: UPDATE
            }),

            ...mapGetters('donation', {
                donation: 'donation'
            })

        }
        
    };

</script>

<style lang="scss" scoped>

    .personalization {
        background-color: $white-100;
        width: 100%;
        max-width: 815px;
        padding: 22px 22px;
        border-radius: 0.688rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;
        transition: all 0.3s ease-out;

        @media (min-width: 576px) { // RED

        }

        @media (min-width: 768px) { // GREEN
            padding: 22px 40px;
        }

        @media (min-width: 992px) { // BLUE

        }

        @media (min-width: 1200px) { // YELLOW

        }

        & > .inner {
            width: 100%;
            position: relative;
        }

        & .form-row {
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            align-items: center;
            //gap: 0.5rem;
            margin: 0 0 0.5rem 0;
            transition: all 0.3s ease-out;

            &:last-of-type {
                margin: 0 0 0 0;
            }

            @media (min-width: 576px) { // RED

            }

            @media (min-width: 768px) { // GREEN
                flex-flow: row;
            }

            @media (min-width: 992px) { // BLUE

            }

            @media (min-width: 1200px) { // YELLOW

            }

        }

        & input[type="email"],
        & input[type="text"] {
            background-color: $neo-light-red;
            font-family: $font-rubik;
            font-size: 0.875rem;
            font-weight: 500;
            color: $neo-grey-dark;
            line-height: 1;
            text-transform: uppercase;
            width: 100%;
            height: 2.250rem;
            padding: 0 1rem;
            border: 3px solid $transparent;
            border-radius: 18px;
            transition: all 0.3s ease-out;

            @media (min-width: 576px) { // RED

            }

            @media (min-width: 768px) { // GREEN
                font-size: 1.125rem;
                padding: 0 1.500rem;
            }

            @media (min-width: 992px) { // BLUE

            }

            @media (min-width: 1200px) { // YELLOW

            }

            &:focus {
                outline: none;
                border: 3px solid $neo-red;
                box-shadow: none;
                border-radius: 18px;
            }

            &::placeholder {
                color: $neo-grey-dark;
                transition: all 0.3s ease-out;
            }

            &.zip-code {
                max-width: none;
                margin: 0.5rem 0 0 0;

                @media (min-width: 576px) { // RED

                }

                @media (min-width: 768px) { // GREEN
                    max-width: 200px;
                    margin: 0 0 0 0.5rem;
                }

                @media (min-width: 992px) { // BLUE

                }

                @media (min-width: 1200px) { // YELLOW

                }
            }
        }

        & .note {
            font-family: $font-rubik;
            font-size: 0.813rem;
            font-weight: 500;
            line-height: 1;
            color: $neo-red;
            text-transform: uppercase;
            padding: 0.5rem 0 0 0;
            transition: all 0.3s ease-out;

            @media (min-width: 576px) { // RED

            }

            @media (min-width: 768px) { // GREEN

            }

            @media (min-width: 992px) { // BLUE

            }

            @media (min-width: 1200px) { // YELLOW

            }
        }
    }

</style>
