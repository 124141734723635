<template>

    <svg class="icon-arrow-right" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20.331" height="18.573" viewBox="0 0 20.331 18.573">
        <defs>
            <clipPath id="icon-arrow-right-clip-path">
                <rect id="Rectangle_299" data-name="Rectangle 299" width="20.331" height="18.573" transform="translate(0 0)" fill="none"/>
            </clipPath>
        </defs>
        <g id="Group_99" data-name="Group 99" transform="translate(0 0)">
            <g id="Group_98" data-name="Group 98" clip-path="url(#icon-arrow-right-clip-path)">
                <path id="Path_251" data-name="Path 251" d="M13.018,17.073l5.813-7.787H1.5" fill="none" stroke="#a11413" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                <path id="Path_252" data-name="Path 252" d="M13.018,1.5l5.813,7.786H1.5" fill="none" stroke="#a11413" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            </g>
        </g>
    </svg>

</template>

<script>

    export default {
    
        name: 'IconArrowRight',
        
        data() {
            return {
                componentName: this.$options.name + ' Component'
            };
        },
        
        created() {
            
        },
        
        mounted() {
            
        },
        
        methods: {
            
        }
        
    };

</script>

<style lang="scss" scoped>

    .icon-arrow-right {
    
    }

</style>
