<template>

    <svg class="logo-neopangea-cropcircle" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22.699" height="26.859" viewBox="0 0 22.699 26.859">
        <defs>
            <clipPath id="logo-neopangea-cropcircle-clip-path">
                <rect id="Rectangle_289" data-name="Rectangle 289" width="22.699" height="26.859" fill="none"/>
            </clipPath>
        </defs>
        <g id="Group_72" data-name="Group 72" clip-path="url(#logo-neopangea-cropcircle-clip-path)">
            <path id="Path_237" data-name="Path 237" d="M19.745,4.269A2.945,2.945,0,0,0,16.811,6.86H11.439V4.8a3.345,3.345,0,0,0,3.031-3.309h-.443a2.9,2.9,0,0,1-2.588,2.867V2.945a1.5,1.5,0,0,0,1.175-1.453,1.508,1.508,0,0,0-3.016,0,1.494,1.494,0,0,0,1.175,1.453V4.359a2.926,2.926,0,0,1-2.141-1.34l-.367.246A3.368,3.368,0,0,0,10.774,4.8V6.86H5.888a2.921,2.921,0,1,0,0,.658h4.886V9.789a8.537,8.537,0,0,0,.308,17.071l-.313-.73a7.813,7.813,0,0,1,0-15.619v.658L9.7,13.316a5.254,5.254,0,1,0,2.813,0l-1.074-2.148v-.658a7.868,7.868,0,0,1,7.575,7.81h.731a8.594,8.594,0,0,0-8.306-8.531V7.518h5.372a2.951,2.951,0,1,0,2.934-3.249M2.954,9.451A2.262,2.262,0,1,1,5.215,6.86H4.159a1.237,1.237,0,1,0,0,.658H5.215A2.282,2.282,0,0,1,2.954,9.451m12.478,8.869a4.326,4.326,0,1,1-4.658-4.26V16.3a2.068,2.068,0,1,0,.665,0V14.061a4.3,4.3,0,0,1,3.993,4.26m4.313-8.869a2.281,2.281,0,0,1-2.262-1.934H18.54a1.237,1.237,0,1,0,0-.658H17.484a2.285,2.285,0,1,1,2.262,2.591" transform="translate(0 -0.001)" fill="#a11413"/>
        </g>
    </svg>

</template>

<script>

    export default {
    
        name: 'LogoNeopangeaCropcircle',
        
        data() {
            return {
                componentName: this.$options.name + ' Component'
            };
        },
        
        created() {
            
        },
        
        mounted() {
            
        },
        
        methods: {
            
        }
        
    };

</script>

<style lang="scss" scoped>

    .logo-neopangea-cropcircle {
    
    }

</style>
