<template>

    <div id="app" data-aos="app">

        <breakpoint-debugger v-if="bpDebugger"></breakpoint-debugger>

        <div class="overlay"
             data-aos="overlay">
        </div>
        <div class="gift-icon"
             data-aos="gift-icon">
            <icon-gift></icon-gift>
        </div>

        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>

        <app-footer></app-footer>

        <zoom v-if="rendered"></zoom>

    </div>

</template>

<script>

import Zoom from './views/Zoom';
import BreakpointDebugger from '@/components/BreakpointDebugger';
import IconGift from '@/components/IconGift';
import AppFooter from '@/components/AppFooter';

let vm = {};

export default {

    name: 'App',

    components: {
        Zoom,
        BreakpointDebugger,
        IconGift,
        AppFooter
    },

    data() {
        return {
            rendered: false,
            bpDebugger: false
        };
    },

    created() {
        vm = this;

        vm.$eventBus.$on('app::ready', () => {
            console.log('App ready...');
            vm.rendered = true;
        });
    }

}

</script>

<style lang="scss">

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        min-height: 100vh;
        width: 100%;
        padding: 30px 20px 20px 76px;
        overflow: hidden;
        position: relative;

        @media (min-width: 576px) { // RED

        }

        @media (min-width: 768px) { // GREEN
            padding: 30px 40px 20px 140px;
        }

        @media (min-width: 992px) { // BLUE
            padding: 30px 50px 20px 210px;
        }

        @media (min-width: 1200px) { // YELLOW
            padding: 80px 20px 40px 210px;
        }

        /*&::after {
            content: '3';
            font-family: $font-rubik;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1;
            text-shadow: 2px 2px 0 $neo-red, -2px -2px 0 $neo-red, 2px -2px 0 $neo-red, -2px 2px 0 $neo-red;
            line-height: 1;
            color: white;
            position: fixed;
            left: 39px;
            top: calc(50vh + 5px);
            z-index: 6;
            transition: all 0.3s ease-out;
        }*/

    }

    .overlay {
        background-color: $neo-off-white;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        mix-blend-mode: multiply;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        pointer-events: none;
        transition: all 0.7s $easypeasy 0.3s;

        @media (min-width: 576px) { // RED

        }

        @media (min-width: 768px) { // GREEN

        }

        @media (min-width: 992px) { // BLUE

        }

        @media (min-width: 1200px) { // YELLOW

        }

        &.aos-animate {
            background-color: $neo-red;
            width: 47px;

            @media (min-width: 576px) { // RED

            }

            @media (min-width: 768px) { // GREEN
                width: 94px;
            }

            @media (min-width: 992px) { // BLUE

            }

            @media (min-width: 1200px) { // YELLOW

            }
        }
    }

    .gift-icon {
        width: 27px;
        height: auto;
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 6;
        transform: translateX(-50%) translateY(-50%);
        transition: all 0.3s ease-out;

        @media (min-width: 576px) { // RED

        }

        @media (min-width: 768px) { // GREEN
            width: 54px;
        }

        @media (min-width: 992px) { // BLUE

        }

        @media (min-width: 1200px) { // YELLOW

        }

        &.aos-animate {
            left: 22px;

            @media (min-width: 576px) { // RED

            }

            @media (min-width: 768px) { // GREEN
                left: 45px;
            }

            @media (min-width: 992px) { // BLUE

            }

            @media (min-width: 1200px) { // YELLOW

            }
        }

        & > svg {
            width: 100%;
            height: 100%;
        }
    }

    // "loader"
    /*[v-cloak] > * {
        opacity: 0;
        transition: all 0.3s ease-out;
    }
    [v-cloak]::after {
        content: '';
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border: 16px solid $neo-yellow;
        border-radius: 50%;
        border-top: 16px solid $neo-red;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        animation: spin 2s linear infinite;
    }*/

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    @keyframes slide {
        100% { left: 0; }
    }

</style>
