/* CHARITIES */

//import {  } from './actions';
//import {  } from './mutations';

const state = {
    charities: [
        {
            id: 'feeding-america',
            name: 'Feeding America',
            description: 'The Feeding America network is the nation’s largest domestic hunger-relief organization, working to connect people with food and end hunger.',
            url: 'https://www.feedingamerica.org'
        },
        {
            id: 'the-red-cross',
            name: 'The Red Cross',
            description: 'Red Cross volunteers and staff work to deliver vital services &mdash; from providing relief and support to those in crisis, to helping you be prepared to respond in emergencies.',
            url: 'https://www.redcross.org'
        },
        {
            id: 'the-als-association',
            name: 'The ALS Association',
            description: 'Our mission is to discover treatments and a cure for ALS, and to serve, advocate for, and empower people affected by ALS to live their lives to the fullest.',
            url: 'https://www.als.org'
        },
        {
            id: 'breast-cancer-alliance',
            name: 'Breast Cancer Alliance',
            description: 'Breast Cancer Alliance is the powerful private breast cancer foundation making a national impact through major investments in early-stage research, fellowships, education and support.',
            url: 'https://breastcanceralliance.org'
        },
        {
            id: 'farmer-veteran-coalition',
            name: 'Farmer Veteran Coalition',
            description: 'FVC is the nation’s largest nonprofit organization assisting veterans–and currently serving members–of the Armed Forces to embark on careers in agriculture. Join our mission of mobilizing veterans to feed America.',
            url: 'https://farmvetco.org'
        },
        {
            id: 'make-a-wish',
            name: 'Make a Wish',
            description: 'Tens of thousands of volunteers, donors and supporters advance the Make-A-Wish&reg; vision to grant the wish of every child diagnosed with a critical illness. In the U.S. and its territories, a wish is granted every 34 minutes. A wish can be that spark that helps these children believe that anything is possible and gives them the strength to fight harder against their illnesses. This one belief guides us and inspires us to grant wishes that change the lives of the kids we serve.',
            url: 'https://wish.org'
        },
        {
            id: 'environmental-defense-fund',
            name: 'Environmental Defense Fund',
            description: 'EDF is one of the world’s leading environmental organizations. We’ve been driving results for more than 50 years.',
            url: 'https://www.edf.org'
        },
        {
            id: 'jane-goodall-institute',
            name: 'Jane Goodall Institute',
            description: 'Your support helps us advance Jane’s vision and work around the world as a force of compassion for all living things.',
            url: 'https://www.janegoodall.org/make-a-difference'
        },
        {
            id: 'habitat-for-humanity',
            name: 'Habitat for Humanity',
            description: 'Habitat for Humanity is a nonprofit organization that helps families build and improve places to call home. We believe affordable housing plays a critical role in strong and stable communities.',
            url: 'https://www.habitat.org'
        },
        {
            id: 'national-womens-law-center',
            name: 'National Womens Law Center',
            description: 'We are advocates, experts, and lawyers who fight for gender justice, taking on issues that are central to the lives of women and girls. We drive change in the courts, in public policy, and in our society, especially for women facing multiple forms of discrimination.',
            url: 'https://nwlc.org'
        },
        {
            id: 'native-american-rights-fund',
            name: 'Native American Rights Fund',
            description: 'Since 1970, the Native American Rights Fund (NARF) has provided legal assistance to Indian tribes, organizations, and individuals nationwide who might otherwise have gone without adequate representation.',
            url: 'https://www.narf.org'
        },
        {
            id: 'legal-defense-and-educational-fund',
            name: 'Legal Defense and Educational Fund',
            description: 'America’s premier legal organization fighting for racial justice.',
            url: 'https://www.naacpldf.org'
        },
        {
            id: 'american-legion',
            name: 'American Legion',
            description: 'The American Legion was chartered and incorporated by Congress in 1919 as a patriotic veterans organization devoted to mutual helpfulness.',
            url: 'https://www.legion.org'
        }
    ]
};

const getters = {

    charities: ({ charities }) => charities,

    charityById: (state) => (id) => {
        return state.charities.find(obj => obj.id === id) || null;
    }

};

const mutations = {

};

const actions = {

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
