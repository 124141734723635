<template>

    <div class="hero">
    
        <h3 class="sponsor"
            data-aos="fade-right"
            data-aos-delay="800">
            <span class="icon">
                <logo-neopangea-cropcircle></logo-neopangea-cropcircle>
            </span>
            <span>{{ sponsorName }}</span>
        </h3>

        <h1 class="title"
            v-html="causeTitle"
            data-aos="fade-right"
            data-aos-delay="900"></h1>

        <h2 class="description"
            v-html="causeDescription"
            data-aos="fade-right"
            data-aos-delay="1000"></h2>
        
    </div>

</template>

<script>

    import LogoNeopangeaCropcircle from '@/components/LogoNeopangeaCropcircle';

    export default {
    
        name: 'Hero',

        components: {
            LogoNeopangeaCropcircle
        },

        data() {
            return {
                componentName: this.$options.name + ' Component',
                sponsorName: 'Neo Pangea\'s',
                causeTitle: 'Totally <br />Regulatory <br />Approved <br />No-Contact <br />Non-Denominational <br /><span class="highlight">Holiday Present</span>',
                causeDescription: 'In place of our usual handmade holiday gift games, we at Neo Pangea would like to offer you a totally safe, COVID-free, and contactless present. Allow us to make a donation in your name to the charities of your choice below.'
            };
        },
        
        created() {
            
        },
        
        mounted() {
            
        },
        
        methods: {
            
        }
        
    };

</script>

<style lang="scss" scoped>

    .hero {
        width: 100%;
        max-width: 940px;
    }

    .sponsor {
        font-family: $font-rubik;
        font-size: 1.250rem;
        font-weight: 700;
        text-align: left;
        text-transform: uppercase;
        color: $neo-red;
        margin: 0 0 24px 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        //gap: 10px;
        mix-blend-mode: multiply;
        transition: all 0.3s ease-out;

        & .icon {
            margin: 0 10px 0 0;
        }
    }

    .title {
        //font-size: 4.063rem;
        font-size: 1.688rem;
        font-weight: 600;
        text-align: left;
        text-transform: uppercase;
        color: $neo-grey-dark;
        white-space: nowrap;
        margin: 0 0 50px 0;
        mix-blend-mode: multiply;
        transition: all 0.3s ease-out;

        @media (min-width: 576px) { // RED

        }

        @media (min-width: 768px) { // GREEN
            font-size: 4.063rem;
            margin: 0 0 50px 0;
        }

        @media (min-width: 992px) { // BLUE
            margin: 0 0 100px 0;
        }

        @media (min-width: 1200px) { // YELLOW

        }

        & ::v-deep .highlight {
            //font-size: 6.250rem;
            font-size: 2.125rem;
            font-weight: 700;
            color: $neo-red;
            transition: all 0.3s ease-out;

            @media (min-width: 576px) { // RED

            }

            @media (min-width: 768px) { // GREEN
                font-size: 6.250rem;
            }

            @media (min-width: 992px) { // BLUE

            }

            @media (min-width: 1200px) { // YELLOW

            }
        }
    }

    .description {
        font-family: $font-rubik;
        //font-size: 1.313rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
        text-align: left;
        text-transform: uppercase;
        color: $neo-red;
        margin: 0 0 50px 0;
        mix-blend-mode: multiply;
        transition: all 0.3s ease-out;

        @media (min-width: 576px) { // RED

        }

        @media (min-width: 768px) { // GREEN
            font-size: 1.313rem;
            margin: 0 0 60px 0;
        }

        @media (min-width: 992px) { // BLUE
            margin: 0 0 120px 0;
        }

        @media (min-width: 1200px) { // YELLOW

        }
    }

</style>
