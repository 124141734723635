<template>

    <div class="root" id="breakpoint-debugger"></div>

</template>

<script>

export default {

    name: 'BreakpointDebugger',

    data() {
        return {};
    },

    created() {
        console.log('%cBreakpointDebugger Component Created', 'color:lime;');
    }

};

</script>

<style lang="scss" scoped>

#breakpoint-debugger {
    background-color: rgba(255,255,255, 1.0); // WHITE
    width: 100%;
    height: 3px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.33);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    pointer-events: none;
    transition: all 0.3s ease-in-out;

    // Small devices (landscape phones, 576px and up) RED
    @media (min-width: 576px) {
        background-color: rgba(237,18,18, 1.0);
    }

    // Medium devices (tablets, 768px and up) GREEN
    @media (min-width: 768px) {
        background-color: rgba(66,163,60, 1.0);
    }

    // Large devices (desktops, 992px and up) BLUE
    @media (min-width: 992px) {
        background-color: rgba(67,141,211, 1.0);
    }

    // Extra large devices (large desktops, 1200px and up) YELLOW
    @media (min-width: 1200px) {
        background-color: rgba(230,232,69, 1.0);
    }

    // Extra large devices (large desktops, 1900px and up) PURPLE
    @media (min-width: 1900px) {
        background-color: rgba(153,1,180, 1.0);
    }


    /*
    // Small devices (landscape phones, 576px and up) RED
    @media (min-width: 576px) {

    }

    // Medium devices (tablets, 768px and up) GREEN
    @media (min-width: 768px) {

    }

    // Large devices (desktops, 992px and up) BLUE
    @media (min-width: 992px) {

    }

    // Extra large devices (large desktops, 1200px and up) YELLOW
    @media (min-width: 1200px) {

    }

    // Extra large devices (large desktops, 1900px and up) PURPLE
    @media (min-width: 1900px) {

    }
    */

}

</style>
