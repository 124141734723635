<template>

    <div class="app-footer"
         data-aos="fade-up"
         data-aos-anchor-placement=".submission">
    
        <a href="https://www.neopangea.com" target="_blank" title="NeoPangea">
            <logo-neopangea></logo-neopangea>
        </a>
        
    </div>

</template>

<script>

    import LogoNeopangea from './LogoNeopangea';

    export default {
    
        name: 'AppFooter',

        components: {
            LogoNeopangea
        },

        data() {
            return {
                componentName: this.$options.name + ' Component'
            };
        },
        
        created() {
            
        },
        
        mounted() {
            
        },
        
        methods: {
            
        }
        
    };

</script>

<style lang="scss" scoped>

    .app-footer {
        width: 100%;
        max-width: 815px;
        padding: 5rem 0 0 0;

        @media (min-width: 576px) { // RED
            padding: 6rem 0 0 0;
        }

        @media (min-width: 768px) { // GREEN
            padding: 8rem 0 0 0;
        }

        @media (min-width: 992px) { // BLUE
            padding: 12rem 0 0 0;
        }

        @media (min-width: 1200px) { // YELLOW

        }

        & a {
            text-decoration: none;
            display: inline-block;
            mix-blend-mode: multiply;
            transition: all 0.3s ease-out;
            will-change: filter, opacity;

            &:focus {
                outline: none;
                box-shadow: none;
                opacity: 0.76;
                filter: grayscale(1);
                transition: all 0.3s ease-out;
            }

            @media(hover: hover) {
                &:hover {
                    opacity: 0.76;
                    filter: grayscale(1);
                    transition: all 0.3s ease-out;
                }
            }
        }
    }

</style>
