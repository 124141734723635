<template>

    <div class="donations">

        <section class="introduction">
            <hero></hero>
        </section>

        <hr data-aos="zoom-in" data-aos-delay="1500" data-aos-anchor-placement=".introduction" />

        <form name="charitable-donation" id="charitable-donation" action ref="donation-form">

            <section class="selection mb-5">
                <div class="section-header h-01"
                     data-index="01"
                     data-aos="fade-in">
                    <span class="number"
                          data-aos="fade-left"
                          data-aos-delay="150"
                          data-aos-anchor=".section-header.h-01">01</span>
                    <div class="meta">
                        <h3 data-aos="fade-right"
                            data-aos-delay="150"
                            data-aos-anchor=".section-header.h-01">Choose Your <span class="highlight">Charities:</span></h3>
                        <h4 data-aos="fade-right"
                            data-aos-delay="200"
                            data-aos-anchor=".section-header.h-01"><span class="error-highlight">Select up to 3 charities</span> from the options below.</h4>
                    </div>
                </div>
                <charity-options :options="charities"></charity-options>
            </section>

            <section class="personalization mb-5">
                <div class="section-header h-02"
                     data-index="02"
                     data-aos="fade-in">
                    <span class="number"
                          data-aos="fade-left"
                          data-aos-delay="150"
                          data-aos-anchor=".section-header.h-02">02</span>
                    <div class="meta">
                        <h3 data-aos="fade-right"
                            data-aos-delay="150"
                            data-aos-anchor=".section-header.h-02">Add Your <span class="highlight">Personalization:</span></h3>
                        <h4 data-aos="fade-right"
                            data-aos-delay="150"
                            data-aos-anchor=".section-header.h-02">Not required, but add if you'd like.</h4>
                    </div>
                </div>
                <personalization></personalization>
            </section>

            <section class="submission">
                <div class="section-header h-03"
                     data-index="03"
                     data-aos="fade-in">
                    <span class="number"
                          data-aos="fade-left"
                          data-aos-delay="150"
                          data-aos-anchor=".section-header.h-03">03</span>
                    <div class="meta">
                        <h3 data-aos="fade-right"
                            data-aos-delay="150"
                            data-aos-anchor=".section-header.h-03">Submit Your <span class="highlight">Present:</span></h3>
                        <h4 data-aos="fade-right"
                            data-aos-delay="150"
                            data-aos-anchor=".section-header.h-03">Click that big, yellow button below..</h4>
                    </div>
                </div>
                <submission></submission>
            </section>

        </form>

    </div>

</template>

<script>

import Hero from '@/components/Hero';
import CharityOptions from '@/components/CharityOptions';
import Personalization from '@/components/Personalization';
import Submission from '@/components/Submission';

import { mapGetters } from 'vuex';

let vm = {};

export default {

    name: 'Donations',

    components: {
        Hero,
        CharityOptions,
        Personalization,
        Submission
    },

    props: {
        msg: String
    },

    data() {
        return {

        };
    },

    computed: {

        ...mapGetters('charities', {
            charities: 'charities'
        })

    },

    mounted() {
        vm = this;

        vm.$nextTick(() => {
            vm.$eventBus.$emit('app::ready');
        });
    }

}

</script>

<style scoped lang="scss">

    hr {
        background-color: transparent;
        background-image: url('../assets/images/hr-snowflakes.png');
        background-size: 367px 35px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        color: transparent;
        width: 100%;
        max-width: 280px;
        height: 35px;
        margin: 0 0 60px 0;
        border: 0 none;
        mix-blend-mode: multiply;
        align-self: center;
        transition: all 0.3s ease-out;

        @media only screen and (min-device-pixel-ratio: 1.25),
        only screen and (min-resolution: 1.25dppx) { // @2x
            background-image: url('../assets/images/hr-snowflakes@2x.png');
        }

        @media (min-width: 576px) { // RED

        }

        @media (min-width: 768px) { // GREEN
            max-width: 940px;
            margin: 0 0 80px 0;
            align-self: initial;
        }

        @media (min-width: 992px) { // BLUE

        }

        @media (min-width: 1200px) { // YELLOW

        }
    }

    .mb-5 {
        margin-bottom: 5rem;
    }

    .donations {
        display: flex;
        flex-flow: column nowrap;
    }

    section {

        &:nth-child(odd) {

            & .section-header {

                &::after {
                    top: -4rem;
                    left: 10%;

                    @media (min-width: 576px) { // RED
                        left: 40%;
                    }

                    @media (min-width: 768px) { // GREEN
                        top: -5rem;
                        left: 40%;
                    }

                    @media (min-width: 992px) { // BLUE

                    }

                    @media (min-width: 1200px) { // YELLOW

                    }
                }
            }
        }

        &:nth-child(even) {

            & .section-header {

                &::after {
                    top: -6rem;
                    right: 20%;

                    @media (min-width: 576px) { // RED
                        right: 50%;
                    }

                    @media (min-width: 768px) { // GREEN
                        top: -9rem;
                        right: 50%;
                    }

                    @media (min-width: 992px) { // BLUE

                    }

                    @media (min-width: 1200px) { // YELLOW

                    }
                }
            }
        }
    }

    .section-header {
        width: 100%;
        max-width: 940px;
        margin: 0 0 2rem 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        //gap: 1.0rem;
        position: relative;
        transition: all 0.3s ease-out;

        @media (min-width: 576px) { // RED

        }

        @media (min-width: 768px) { // GREEN
            align-items: center;
            //gap: 2.5rem;
        }

        @media (min-width: 992px) { // BLUE

        }

        @media (min-width: 1200px) { // YELLOW

        }

        &::after {
            content: attr(data-index);
            font-family: $font-rubik;
            font-size: 16.50rem;
            font-weight: 700;
            line-height: 1;
            color: $neo-red;
            opacity: 0.08;
            display: block;
            position: absolute;
            z-index: -1;
            mix-blend-mode: multiply;
            transition: all 0.3s ease-out;

            @media (min-width: 576px) { // RED

            }

            @media (min-width: 768px) { // GREEN
                font-size: 24.125rem;
            }

            @media (min-width: 992px) { // BLUE
                font-size: 41.250rem;
            }

            @media (min-width: 1200px) { // YELLOW

            }
        }

        & .number {
            font-family: $font-rubik;
            font-size: 3.25rem;
            font-weight: 700;
            color: $neo-red;
            line-height: 1;
            letter-spacing: normal;
            -webkit-text-fill-color: $transparent;
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: $neo-red;
            margin: 0 1rem 0 -1rem;
            display: block;
            transition: all 0.3s ease-out;

            @media (min-width: 576px) { // RED
                font-size: 4.25rem;
            }

            @media (min-width: 768px) { // GREEN
                font-size: 8.250rem;
                -webkit-text-stroke-width: 3px;
                margin: 0 1rem 0 -1rem;
            }

            @media (min-width: 992px) { // BLUE
                font-size: 13.500rem;
                margin: 0 1rem 0 -4rem;
            }

            @media (min-width: 1200px) { // YELLOW

            }
        }

        & .meta {
            text-align: left;

            & h3 {
                font-family: $font-rubik;
                font-size: 1.250rem;
                font-weight: 700;
                color: $neo-red;
                line-height: 1;
                letter-spacing: normal;
                text-transform: uppercase;
                mix-blend-mode: multiply;
                transition: all 0.3s ease-out;

                @media (min-width: 576px) { // RED
                    font-size: 1.50rem;
                }

                @media (min-width: 768px) { // GREEN
                    font-size: 2.125rem;
                }

                @media (min-width: 992px) { // BLUE
                    font-size: 2.813rem;
                }

                @media (min-width: 1200px) { // YELLOW

                }

                & .highlight {
                    font-family: $font-rubik;
                    font-size: 1.413rem;
                    font-weight: 700;
                    color: $neo-yellow;
                    line-height: 1;
                    letter-spacing: normal;
                    display: block;
                    transition: all 0.3s ease-out;

                    @media (min-width: 576px) { // RED
                        font-size: 1.875rem;
                    }

                    @media (min-width: 768px) { // GREEN
                        font-size: 2.5rem;
                    }

                    @media (min-width: 992px) { // BLUE
                        font-size: 3.375rem;
                    }

                    @media (min-width: 1200px) { // YELLOW

                    }
                }
            }

            & h4 {
                font-family: $font-rubik;
                font-size: 0.938rem;
                font-weight: 500;
                color: $neo-red;
                line-height: 1;
                letter-spacing: normal;
                text-transform: uppercase;
                mix-blend-mode: multiply;
                transition: all 0.3s ease-out;

                @media (min-width: 576px) { // RED
                    font-size: 1rem;
                }

                @media (min-width: 768px) { // GREEN
                    font-size: 1.125rem;
                }

                @media (min-width: 992px) { // BLUE
                    font-size: 1.313rem;
                }

                @media (min-width: 1200px) { // YELLOW

                }

                & .error-highlight {
                    position: relative;

                    &.has-errors {

                        &::before {
                            content: '';
                            background-color: yellow;
                            width: calc(100% + 0.5rem);
                            height: calc(100% + 0.25rem);
                            margin: -0.12rem 0 0 -0.25rem;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: -1;
                        }
                    }
                }

            }
        }
    }

</style>
