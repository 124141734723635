<template>

    <div class="media-wrapper">

        <iframe :src="silenceBumper" type="audio/mp3" allow="autoplay" id="audio-bumper" style="display:none"></iframe>

        <video class="zoom-call" preload="auto" playsinline loop>
            <source v-for="source in enabledSources" :src="source.source" :type="source.type" :key="source.source" />
        </video>

    </div>

</template>

<script>

    let vm = {};

    export default {
    
        name: 'ZoomCall',
        
        data() {
            return {
                componentName: this.$options.name + ' Component',
                silenceBumper: require('../assets/audio/silence.mp3'),
                sources: [
                    {
                        source: 'https://cdn.neopangea.com/video/NeoPangea-Holiday-Fake-Zoom-Call.webm',
                        type: 'video/webm',
                        enabled: true
                    },
                    {
                        source: 'https://cdn.neopangea.com/video/NeoPangea-Holiday-Fake-Zoom-Call.mp4',
                        type: 'video/mp4',
                        enabled: true
                    }
                ],
                video: null,
                duration: null,
                holdTime: 58.660,
                connected: false
            };
        },

        computed: {

            enabledSources() {
                return this.sources.filter(obj => obj.enabled);
            }

        },

        created() {
            vm = this;

            vm.$eventBus.$on('zoom::connected', () => {
                console.log('Connected to Zoom conference...');
                vm.connected = true;
            });
        },

        watch: {
            connected(nVal) {
                if (nVal) {
                    vm.video.play();
                }
            }
        },
        
        mounted() {
            vm = this;

            vm.video = vm.$el.querySelector('video');

            vm.video.addEventListener('loadedmetadata', function() {
                vm.duration = vm.video.duration;
            });

            /*vm.video.addEventListener('timeupdate', function() {
                console.log(this.currentTime);
                if (this.currentTime >= vm.holdTime) {
                    this.pause();
                }
            });*/
        },
        
        methods: {
            
        }
        
    };

</script>

<style lang="scss" scoped>

    .media-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
    }

    .zoom-call {
        width: 100vw;
        height: 100vh;
    }

</style>
