import { EventBus } from './EventBus';

export default {

    EventBus,
    // eslint-disable-next-line
    install(Vue, opts) {
        Vue.prototype.$eventBus = EventBus;
    }

};
